import { Link } from "gatsby";
import * as React from "react";

import PageLayout from "../components/PageLayout";
import Seo from "../components/Seo";

const ThankYouPage = () => (
  <PageLayout>
    <Seo title="Thank You" slug="/thank-you" />
    <section className="thank-you">
      <h1>Thanks for Contacting Us!</h1>
      <p>Your message has been sent. We will be in touch shortly. </p>
      <Link to="/">Go back home</Link>
    </section>
  </PageLayout>
);
export default ThankYouPage;
